// extracted by mini-css-extract-plugin
export var column = "Heading__column__Bdidr";
export var flex = "Heading__flex__r_pIG";
export var flexColumn = "Heading__flexColumn__xi3TI";
export var gap1 = "Heading__gap1__mdiC7";
export var gap2 = "Heading__gap2__qpxHl";
export var gap3 = "Heading__gap3__NN2ZY";
export var gap4 = "Heading__gap4__Z8AjQ";
export var gap5 = "Heading__gap5__Y2uk0";
export var h1 = "Heading__h1__dxBPE";
export var h2 = "Heading__h2__Raelb";
export var h3 = "Heading__h3__S077j";
export var h4 = "Heading__h4__bxzju";
export var h5 = "Heading__h5__uCEKj";
export var row = "Heading__row__byBio";