// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../styleguide/components/Paragraph/P.res.js";
import * as H3 from "../../../styleguide/components/Heading/H3.res.js";
import * as Svg from "../../../styleguide/components/Svg/Svg.res.js";
import * as HelpBlockScss from "./HelpBlock.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = HelpBlockScss;

function HelpBlock(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Svg.make, {
                      title: "Help",
                      viewBoxWidth: "60",
                      viewBoxHeight: "60",
                      children: JsxRuntime.jsx("g", {
                            children: JsxRuntime.jsx("g", {
                                  children: JsxRuntime.jsx("path", {
                                        d: "M60 30.1C60 30.1 60 30 60 30 60 30 60 29.9 60 29.9 60 25.5 59 21.2 57.1 17.1L57.1 17.1 57.1 12.9C57.1 7.3 52.6 2.9 47.1 2.9L42.8 2.9 42.8 2.9C38.8 1 34.5 0 30.1 0 30.1 0 30 0 30 0 30 0 29.9 0 29.9 0 25.5 0 21.2 1 17.1 2.9L17.1 2.9 12.9 2.9C7.3 2.9 2.9 7.3 2.9 12.9L2.9 17.1 2.9 17.1C1 21.2 0 25.5 0 29.9 0 29.9 0 30 0 30 0 30 0 30.1 0 30.1 0 34.5 1 38.8 2.9 42.8L2.9 42.8 2.9 47.1C2.9 52.6 7.4 57.1 12.9 57.1L17.2 57.1 17.2 57.1C21.2 59 25.5 60 29.9 60 30 60 30 60 30 60 30 60 30.1 60 30.1 60 34.5 60 38.8 59 42.9 57.1L42.9 57.1 47.2 57.1C52.7 57.1 57.2 52.6 57.2 47.1L57.2 42.8 57.1 42.8C59 38.8 60 34.5 60 30.1ZM47.1 28.6C46.7 24.5 45 20.8 42.1 17.9 39.1 14.9 35.3 13.3 31.4 13L31.4 2.9C37.9 3.3 44.3 5.9 49.2 10.8 54.1 15.7 56.7 22.1 57.1 28.6L47.1 28.6 47.1 28.6ZM19.9 40.1C14.3 34.5 14.3 25.5 19.9 19.9 22.7 17.1 26.3 15.7 30 15.7 33.7 15.7 37.3 17.1 40.1 19.9 42.8 22.6 44.3 26.2 44.3 30 44.3 33.8 42.8 37.4 40.1 40.1 34.5 45.7 25.5 45.7 19.9 40.1ZM28.6 13C24.7 13.3 20.9 14.9 17.9 17.9 14.9 20.8 13.3 24.7 13 28.6L2.9 28.6C3.3 22.1 5.9 15.7 10.8 10.8 15.8 5.9 22.1 3.3 28.6 2.9L28.6 13 28.6 13ZM13 31.4C13.3 35.3 14.9 39.1 17.9 42.1 20.8 45.1 24.7 46.7 28.6 47L28.6 57.1C22.1 56.7 15.7 54.1 10.8 49.2 5.9 44.3 3.3 37.9 2.9 31.4L13 31.4ZM31.4 47C35.3 46.7 39.1 45.1 42.1 42.1 45 39.2 46.7 35.5 47.1 31.4L57.1 31.4C56.7 37.9 54.1 44.2 49.2 49.2 44.3 54.1 37.9 56.7 31.4 57L31.4 47 31.4 47ZM54.2 12.4C53.3 11.1 52.3 9.9 51.2 8.8 50.1 7.7 48.9 6.7 47.6 5.8 51.2 6 54 8.8 54.2 12.4ZM12.4 5.8C11.1 6.7 9.9 7.7 8.8 8.8 7.7 9.9 6.7 11.1 5.8 12.4 6 8.8 8.8 6 12.4 5.8ZM5.8 47.6C6.7 48.9 7.7 50.1 8.8 51.2 9.9 52.3 11.1 53.3 12.4 54.2 8.8 54 6 51.2 5.8 47.6ZM47.6 54.2C48.9 53.3 50.1 52.3 51.2 51.2 52.4 50.1 53.3 48.9 54.2 47.6 54 51.2 51.2 54 47.6 54.2Z"
                                      }),
                                  fill: "#18C3BB"
                                }),
                            fill: "none"
                          })
                    }),
                JsxRuntime.jsx(H3.make, {
                      className: css.helpHeading,
                      children: "We Are Ready to Help"
                    }),
                JsxRuntime.jsx("div", {
                      children: "There's an entire company behind the website on standby to answer questions and support your technologies."
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(P.make, {
                              children: JsxRuntime.jsxs("a", {
                                    children: [
                                      JsxRuntime.jsx(Svg.make, {
                                            title: "Phone",
                                            viewBoxWidth: "16",
                                            viewBoxHeight: "16",
                                            children: JsxRuntime.jsx("g", {
                                                  children: JsxRuntime.jsx("g", {
                                                        children: JsxRuntime.jsx("g", {
                                                              children: JsxRuntime.jsx("g", {
                                                                    children: JsxRuntime.jsx("path", {
                                                                          id: "Path",
                                                                          d: "M11.2284725,11.228434 C9.64506883,12.8118113 7.81146457,14.3253896 7.08686289,13.6006 C6.04906048,12.5630149 5.40925899,11.6592278 3.12185368,13.4976015 C0.834248368,15.3367752 2.59125245,16.5621576 3.59645478,17.5677432 C4.75645748,18.7275266 9.08066752,17.6287424 13.3548774,13.3552035 C17.6284874,9.08066469 18.7280899,4.75652657 17.5672872,3.59614318 C16.5618849,2.59075757 15.3364821,0.834382702 13.4980778,3.12214996 C11.6592735,5.40891724 12.5626756,6.04890808 13.600678,7.08669323 C14.3250797,7.81148285 12.8118762,9.64485662 11.2284725,11.228434 Z",
                                                                          transform: "translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000) "
                                                                        }),
                                                                    id: "Icon-/-Phone",
                                                                    transform: "translate(40.000000, 292.000000)"
                                                                  }),
                                                              id: "Group-2",
                                                              fill: "#98A0B0",
                                                              transform: "translate(830.000000, 150.000000)"
                                                            }),
                                                        id: "Sign-up-page",
                                                        transform: "translate(-872.000000, -444.000000)"
                                                      }),
                                                  id: "Site",
                                                  fill: "none"
                                                })
                                          }),
                                      "+1 833-471-7100"
                                    ],
                                    className: "flex flex-row",
                                    href: "tel:+1-833-471-7100"
                                  })
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: JsxRuntime.jsxs("a", {
                                    children: [
                                      JsxRuntime.jsx(Svg.make, {
                                            title: "Email",
                                            viewBoxWidth: "18",
                                            viewBoxHeight: "16",
                                            className: css.svgEmail,
                                            children: JsxRuntime.jsx("g", {
                                                  children: JsxRuntime.jsxs("g", {
                                                        children: [
                                                          JsxRuntime.jsx("rect", {
                                                                style: {
                                                                  stroke: "#98A0B0",
                                                                  strokeWidth: "2"
                                                                },
                                                                height: "14",
                                                                width: "16",
                                                                rx: "2",
                                                                x: "2",
                                                                y: "3"
                                                              }),
                                                          JsxRuntime.jsx("polygon", {
                                                                fill: "#98A0B0",
                                                                points: "2.5 5.5 10 10.5 17.5 5.5 19 7 10 13 1 7"
                                                              })
                                                        ],
                                                        transform: "translate(-871 -403)translate(830 150)translate(40 251)"
                                                      }),
                                                  fill: "none"
                                                })
                                          }),
                                      "info@datacenters.com"
                                    ],
                                    className: "flex flex-row",
                                    href: "mailto:info@datacenters.com"
                                  })
                            })
                      ],
                      className: css.helpConacts
                    })
              ],
              className: css.helpBlock
            });
}

var make = HelpBlock;

export {
  css ,
  make ,
}
/* css Not a pure module */
