// extracted by mini-css-extract-plugin
export var column = "HelpBlock__column__mvDLX";
export var flex = "HelpBlock__flex__g2wX4";
export var flexColumn = "HelpBlock__flexColumn__bC_0D";
export var gap1 = "HelpBlock__gap1__NiyvB";
export var gap2 = "HelpBlock__gap2__zpaMo";
export var gap3 = "HelpBlock__gap3__HJbki";
export var gap4 = "HelpBlock__gap4__qnJ1W";
export var gap5 = "HelpBlock__gap5__PJjmh";
export var helpBlock = "HelpBlock__helpBlock__H1ZzG";
export var helpConacts = "HelpBlock__helpConacts__ygJmS";
export var helpHeading = "HelpBlock__helpHeading__YSLWA";
export var row = "HelpBlock__row__OYjKy";
export var svgEmail = "HelpBlock__svgEmail__d4mK2";